
.gradient-section {
    display: flex;
    padding: 10px 0;
    background: linear-gradient(to right, purple, orange);
    align-items: center;
    justify-content: space-between;
    /*width: 100%;*/
    height: 5rem;
}
.rotated-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #333333;
    height: 40px;
    transform: rotate(-5deg);
    position: absolute;
    width: 100%;
    /*display: inline;*/
    overflow: hidden;
}

.lineSpacer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
}
.text-item {
    color: white;
    margin: 0 10px;
    font-weight: bold;
}

/* Optional: Add arrow icons or symbols between the text items if needed */
.text-item:after {
    content: "➔";
    margin-left: 10px;
}

.text-item:last-child:after {
    content: "";
}
